import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let PageNotFound = class PageNotFound extends Vue {
    goHome() {
        this.$router.push({
            name: 'main.home',
        });
    }
};
PageNotFound = __decorate([
    Component({})
], PageNotFound);
export default PageNotFound;
